<template>
  <svg
    width="142"
    height="131"
    viewBox="0 0 142 131"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_dii)">
      <rect
        width="110.192"
        height="71.6431"
        rx="4.23819"
        transform="matrix(0.998961 0.0455757 -0.046094 0.998937 27.3027 46.7402)"
        fill="white"
      />
    </g>
    <path
      d="M26.9921 53.4718C27.0997 51.1406 27.1535 49.9751 27.6412 49.1019C28.0888 48.3005 28.7845 47.6659 29.6235 47.2935C30.5376 46.8878 31.7033 46.941 34.0345 47.0474L130.648 51.4552C132.979 51.5615 134.145 51.6147 135.018 52.1019C135.819 52.5492 136.453 53.2444 136.825 54.0833C137.231 54.9971 137.177 56.1627 137.069 58.4939L135.955 82.6343L25.8782 77.6122L26.9921 53.4718Z"
      fill="url(#paint0_linear)"
    />
    <rect
      width="15.5399"
      height="13.3453"
      rx="2.82546"
      transform="matrix(0.998961 0.0455757 -0.046094 0.998937 36.3477 69.6025)"
      fill="#EDF0F8"
    />
    <rect
      width="43.7941"
      height="6.32145"
      rx="2.82546"
      transform="matrix(0.998961 0.0455757 -0.046094 0.998937 33.7402 92.5962)"
      fill="#EDF0F8"
    />
    <rect
      width="21.8971"
      height="6.32145"
      rx="2.82546"
      transform="matrix(0.998961 0.0455757 -0.046094 0.998937 33.2939 102.271)"
      fill="#EDF0F8"
    />
    <g filter="url(#filter1_dii)">
      <rect
        width="110.151"
        height="71.6695"
        rx="4.23819"
        transform="matrix(0.966291 -0.257454 0.26019 0.965557 4 29.3589)"
        fill="white"
      />
    </g>
    <path
      d="M5.75335 35.8655C5.14616 33.6123 4.84257 32.4856 5.04171 31.5069C5.2245 30.6086 5.69451 29.7945 6.38109 29.187C7.12912 28.5252 8.2566 28.2248 10.5116 27.624L103.927 2.73489C106.182 2.13408 107.309 1.83368 108.29 2.03494C109.19 2.21967 110.006 2.69102 110.616 3.37812C111.281 4.12672 111.584 5.25335 112.192 7.5066L118.482 30.8514L12.0441 59.2103L5.75335 35.8655Z"
      fill="url(#paint1_linear)"
    />
    <rect
      width="15.5342"
      height="13.3502"
      rx="2.82546"
      transform="matrix(0.966291 -0.257454 0.26019 0.965557 19.583 48.4248)"
      fill="#EDF0F8"
    />
    <rect
      width="43.7781"
      height="6.32378"
      rx="2.82546"
      transform="matrix(0.966291 -0.257454 0.26019 0.965557 24.1006 71.123)"
      fill="#EDF0F8"
    />
    <rect
      width="21.8891"
      height="6.32378"
      rx="2.82546"
      transform="matrix(0.966291 -0.257454 0.26019 0.965557 26.6211 80.479)"
      fill="#EDF0F8"
    />
    <defs>
      <filter
        id="filter0_dii"
        x="19.9957"
        y="45.5585"
        width="121.389"
        height="84.6034"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2.82546" />
        <feGaussianBlur stdDeviation="2.1191" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0721875 0 0 0 0 0.141808 0 0 0 0 0.320833 0 0 0 0.06 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3.73589" />
        <feGaussianBlur stdDeviation="1.86794" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1.41273" />
        <feGaussianBlur stdDeviation="1.05955" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="effect2_innerShadow" result="effect3_innerShadow" />
      </filter>
      <filter
        id="filter1_dii"
        x="0.742765"
        y="0.559926"
        width="131.601"
        height="104.091"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2.82546" />
        <feGaussianBlur stdDeviation="2.1191" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0721875 0 0 0 0 0.141808 0 0 0 0 0.320833 0 0 0 0.06 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3.73589" />
        <feGaussianBlur stdDeviation="1.86794" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1.41273" />
        <feGaussianBlur stdDeviation="1.05955" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="effect2_innerShadow" result="effect3_innerShadow" />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="137.38"
        y1="51.7623"
        x2="2.32409"
        y2="58.5712"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C401AE" />
        <stop offset="0.228801" stop-color="#6430DB" />
        <stop offset="0.8125" stop-color="#1656FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="110.438"
        y1="0.999987"
        x2="-16.0531"
        y2="48.554"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C401AE" />
        <stop offset="0.228801" stop-color="#6430DB" />
        <stop offset="0.8125" stop-color="#1656FF" />
      </linearGradient>
    </defs>
  </svg>
</template>
